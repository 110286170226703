<template lang="pug">
  .spoiler(:class="classList")
      button._header(@click="toggle" v-if="!(once && isShown)")
          ._header-text {{ isShown && titleHide ? titleHide : title }}
          ._header-arrow
              svg-icon._header-arrow-svg(icon-name="chevron-down")
          template(v-if="accent === accentVariants.BORDERED")
              ._header-border-decor.-top.-left
              ._header-border-decor.-top.-right
              ._header-border-decor.-bottom.-left
              ._header-border-decor.-bottom.-right
      transition(name="fade")
          ._content(v-if="isShown")
              slot

</template>

<script>
const ACCENT = {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    BORDERED: "bordered",
};

export default {
    name: "Spoiler",
    props: {
        title: {
            type: String,
            required: true,
        },
        titleHide: {
            type: String,
            required: false,
            default: "",
        },
        isShownByDefault: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * Will toggle content only once
         */
        once: {
            type: Boolean,
            required: false,
            default: false,
        },
        /**
         * null | true | false
         *
         * @description
         * Force show statement outside of component
         * null - forcing disabled
         * true - show content
         * false - hide content
         */
        forceShow: {
            type: Boolean || null,
            required: false,
            default: null,
        },
        /**
         * 'primary' | 'secondary'
         */
        accent: {
            type: String,
            required: false,
            default: ACCENT.PRIMARY,
        },
    },
    data() {
        return {
            isShown: false,
        };
    },
    computed: {
        classList() {
            return {
                spoiler_accent_primary:
                    this.accent === this.accentVariants.PRIMARY,
                spoiler_accent_secondary:
                    this.accent === this.accentVariants.SECONDARY,
                spoiler_accent_bordered:
                    this.accent === this.accentVariants.BORDERED,
                "spoiler_is-disabled": this.disabled,
                "spoiler_is-shown": this.isShown,
            };
        },
        accentVariants() {
            return ACCENT;
        },
        isForced() {
            return this.forceShow !== null;
        },
    },
    watch: {
        forceShow() {
            if (this.isForced) {
                this.isShown = this.forceShow;
            }
        },
    },
    created() {
        this.isShown = this.isShownByDefault;
    },
    methods: {
        toggle() {
            this.isShown = !this.isShown;
            this.isShown ? this.emitShow() : this.emitHide();
        },
        emitShow() {
            this.$emit("show");
        },
        emitHide() {
            this.$emit("hide");
        },
    },
};
</script>

<style lang="scss" src="./spoiler.scss" scoped />
