<template lang="pug">
    .person-short-card(:class="person.type")
        ._number {{ index }}
        ._info-wrap
            ._info
                ._avatar
                    label(:for="`person-${index}`" v-if="severalPersons").checkbox
                        input(type="checkbox" :id="`person-${index}`" v-model="person.checked")._real
                        ._fake
                            svg-icon(icon-name="check")._icon
                    ._avatar-img
                        user-photo(:photo="person.personAvatar")
                          .checkmark(v-if="person.allFeaturesFilled")
                            svg-icon(icon-name="check")
                        label(:for="`features-${index}`" v-if="person.allFeaturesFilled && (isAdmin || isEditor)").checkbox
                            input(type="checkbox" :id="`features-${index}`" v-model="person.allFeaturesFilled" disabled)._real
                            ._fake
                                svg-icon(icon-name="check")._icon
                ._info-group
                    router-link(v-if="tab === 'requests'", :to="getPersonLink(person.requestId)")._name {{ personName }}
                    router-link(v-else, :to="getPersonLink()")._name {{ personName }}
                    ._type
                        span(v-if="leaderType === '_locked_' || leaderType === '_hidden_'") {{ locale("typeLocked") }}
                        span(v-else) {{ leaderType }}
                    router-link(:to="{name: 'messages', params: {chatId: person.userId}}" v-if="person.type === 'friends'")._msg-link {{ locale("sendMessage") }}
                ._compare-spoiler(v-if="hasCompareOptions && tab !== 'requests'")
                    spoiler(
                        :title="locale('compare')"
                        :title-hide="locale('hide')"
                        accent="bordered"
                        :disabled="!person.analysesAvailable"
                        :is-shown-by-default="pageSize > 991 || person.compareStatus"
                        :once="pageSize > 991"
                        :force-show="activePersonDetailsId !== person.personId ? false : null"
                        @show="emitChangeActivePersonDetailsId(person.personId)"
                        @hide="emitChangeActivePersonDetailsId(null)"
                    )
                        portal(:to="`compare-spoiler-content-${person.personId}`")
                            transition(name="fade" :appear="pageSize < 991")
                                ._compare-list(v-if="(!severalPersons && pageSize < 991 && tab !== 'requests') || (pageSize > 991 && tab !== 'requests')")
                                    ._compare(
                                        v-for="compare in person.personCompare"
                                        v-if="serviceTypes[compare.compareType].type === 'compare'"
                                        :class="{gold: person.analysesAvailable, green: compare.value > 0, active: serviceTypes[compare.compareType].active}"
                                        @click.stop="getCompare(compare)"
                                    )
                                        element-decor._decor(size="extra-small", design="design", v-if="pageWidth > 991")
                                        ._compare-name {{ $t(`serviceTypes.${serviceTypes[compare.compareType].id}`) }}
                                        ._compare-icon-box
                                            svg-icon(:icon-name="serviceTypes[compare.compareType].icon" :class="`person-short-card__compare-icon_${serviceTypes[compare.compareType].icon}`")._compare-icon
                                        ._compare-percent(v-if="compare.value > 0") {{ (compare.value > 100) ? '>100' : compare.value }}%

                                    ._compare(
                                        v-for="indicator in person.personIndicator"
                                        v-if="serviceTypes[indicator.indicatorType].type === 'indicator'"
                                        :class="{gold: person.analysesAvailable, green: indicator.value > 0, active: serviceTypes[indicator.indicatorType].active}"
                                        @click.stop="getIndicator(indicator)")
                                        element-decor._decor(size="extra-small", design="design", v-if="pageWidth > 991")
                                        ._compare-name {{ $t(`serviceTypes.${serviceTypes[indicator.indicatorType].id}`) }}
                                        ._compare-icon-box
                                            svg-icon(:icon-name="serviceTypes[indicator.indicatorType].icon" :class="`person-short-card__compare-icon_${serviceTypes[indicator.indicatorType].icon}`")._compare-icon
                                        ._compare-percent(v-if="indicator.value > 0") {{ indicator.value > 100 ? '>100' : indicator.value }}%

                                    ._compare.gold.delete(@click="confirmDeleteFromFriends" v-if="person.type === 'friends'")
                                        ._compare-name {{ locale("removeFromFriends") }}
                                        ._compare-icon-box
                                            svg-icon(icon-name="cross")._compare-icon.-cross

                router-link(v-if="person.type === 'friends'" :to="{name: 'messages', params: {chatId: person.userId}}")._msg-icon-box
                    svg-icon(icon-name="message")._msg-icon
                ._delete-friend(@click="confirmDeleteFromFriends" v-if="person.type === 'friends' & pageWidth <= 991")
                    ._delete-friend-icon-box
                        svg-icon(icon-name="cross")

        portal-target._compare-spoiler-content(:name="`compare-spoiler-content-${person.personId}`")

        ._actions(v-if="tab === 'requests'")
            ui-button(type="bordered" @click.native="friendRequestResponse(person.requestId, 'ADDED', index)")._action
                template(v-slot:content)
                    svg-icon(icon-name="check")
                    | {{ locale("acceptRequest") }}
            ui-button(type="bordered" color="gray" @click.native="friendRequestResponse(person.requestId, 'DECLINED', index)")._action.-decline
                template(v-slot:content)
                    svg-icon(icon-name="cross")
                    | {{ locale("declineRequest") }}

</template>

<script>
import { friendMixins } from "../../../mixins/friendMixins";
import spoiler from "@/components/app/spoiler/spoiler";
import { compareMixins } from "@/mixins/compare.mixins";
import gql from "graphql-tag";
import friendResponseMutation from "@/graphql/mutations/friendResponse.mutation.graphql";
import friendRemoveMutation from "@/graphql/mutations/friendRemove.mutation.graphql";
import UiButton from "../../ui/ui-button/ui-button";
import UserPhoto from "../../ui/user-photo/user-photo";
import ElementDecor from "@/components/ui/element-decor/element-decor.vue";

export default {
    name: "PersonShortCard",
    components: {
        UserPhoto,
        UiButton,
        spoiler,
        ElementDecor,
    },
    mixins: [friendMixins, compareMixins],
    props: {
        person: {
            require: true,
            type: Object,
            default: () => ({
                type: "",
                personAvatar: "",
                personLeaderType: "",
                requestId: "",
                personIndicator: [],
                personCompare: {
                    analysesAvailable: false,
                },
                allFeaturesFilled: false
            }),
        },
        index: {
            require: true,
            type: Number,
            default: 0,
        },
        severalPersons: {
            type: Boolean,
            require: true,
            default: false,
        },
        tab: {
            require: true,
            type: String,
            default: "",
        },
        activePersonDetailsId: {
            type: String || null,
            required: false,
            default: null,
        },
        hasCompareOptions: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    data: () => ({
        activePerson: "",
        activeType: {},
    }),
    computed: {
        auth: (state) => state.$store.getters["auth/authStatus"],
        pageSize: (state) => state.$store.getters["windowWidth"],
        isLeastOneOpen: (state) =>
            Object.values(state.serviceTypes).find((item) => item.active),
        serviceTypes: function () {
            let types = {};
            this.$store.getters["serviceTypes"].forEach(
                (type) => (types[type.id] = type),
            );
            return types;
        },
        personUuid: (state) => state.person.personId,
        personName: (state) =>
            state.person.personName === "Newbie"
                ? state.locale("newbie", {}, true)
                : state.person.personName,
        personType: (state) => state.person.type,
        leaderType: (state) => state.person.personLeaderType,
        personAvatar: (state) => state.person.personAvatar,
        allFeaturesFilled: (state) => state.person.allFeaturesFilled
    },
    methods: {
        async getCompare(compare) {
            // personName
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если у меня нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            if (compare.compareType) {
                this.activeType = this.serviceTypes[compare.compareType];
            }

            await this.getCompareResult(
                this.person.personId,
                this.activeType.id,
            )
                .then((r) => {
                    const result = r.data.compareWithPersonGet,
                        title = result.resultTitle,
                        percent = result.resultValue,
                        desc = result.resultDescription;
                    if (!percent) {
                        this.offerToBuyCompare(this.activeType.id);
                        return;
                    }

                    const compareInList = this.person.personCompare.find(
                        (compare) => compare.compareType === this.activeType.id,
                    );
                    compareInList.value = percent > 100 ? ">100" : percent;

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: this.activeType,
                            photo: this.personAvatar,
                            personUuid: this.personUuid,
                            personName: this.personName,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        async getIndicator(indicator) {
            // personName
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если у меня нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            if (indicator.indicatorType) {
                this.activeType = this.serviceTypes[indicator.indicatorType];
            }

            await this.getIndicatorResult(
                this.person.personId,
                this.activeType.id,
            )
                .then((r) => {
                    const result = r.data.personIndicatorGet,
                        title = result.resultTitle,
                        percent = result.resultValue,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyIndicator(this.activeType.id);
                        return;
                    }

                    const indicatorInList = this.person.personIndicator.find(
                        (indicator) =>
                            indicator.indicatorType === this.activeType.id,
                    );
                    indicatorInList.value = percent > 100 ? ">100" : percent;

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: this.activeType,
                            photo: this.personAvatar,
                            personUuid: this.personUuid,
                            personName: this.personName,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch((e) => {
                    console.log(e);
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        getPersonLink(requestId) {
            if (this.person.personPublicUrl) {
                return {
                    name: "public_person",
                    params: {
                        uuid: this.person.personPublicUrl,
                    },
                    query: {
                        request: requestId,
                    },
                };
            } else {
                return {
                    name: "person",
                    params: {
                        uuid: this.person.personId,
                    },
                    query: {
                        request: requestId,
                    },
                };
            }
        },
        friendRequestResponse(requestId, response, rowIndex) {
            this.$apollo
                .query({
                    query: gql`
                        ${friendResponseMutation}
                    `,
                    variables: {
                        requestId: requestId,
                        status: response,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.$emit("changeRequestsCount", response, rowIndex);
                })
                .catch(() => {
                    let title = "error",
                        text = "retry";
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                    );
                });
        },
        emitChangeActivePersonDetailsId(id) {
            this.$emit("change-active-person-details-id", id);
        },
        confirmDeleteFromFriends() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("confirmDeleteFriendTitle"),
                text: this.locale("confirmDeleteFriendText", {
                    user: this.person.personName,
                }),
                buttons: true,
                confirmText: this.locale("confirmDeleteFriendBtn"),
                callback: this.deleteFromFriends,
            });
        },
        async deleteFromFriends() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${friendRemoveMutation}
                    `,
                    variables: {
                        userId: this.person.userId,
                    },
                })
                .then(() => {
                    this.$emit("removed");
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "person-short-card";
</style>
