<template lang="pug">
    .person-list(:class="type")
        ._head
            ._tabs
                router-link._tab(to="/persons/celebrity" active-class="person-list__tab_active" tag="button" v-if="isMobileView") {{ locale("celebrities") }}
                router-link._tab(to="/persons/compare-with-me" active-class="person-list__tab_active" tag="button" v-if="isMobileView && isCelebrity") {{ locale("fans") }}
                router-link._tab(to="/persons/friends" active-class="person-list__tab_active" tag="button" v-if="isMobileView || type === 'friends' || type === 'requests'") {{ locale("friends") }}
                    span._tab-count {{ totalFriends }}
                router-link._tab(to="/persons/requests" active-class="person-list__tab_active" tag="button" v-if="isMobileView || type === 'friends' || type === 'requests'") {{ locale("friends-requests") }}
                    span._tab-count {{ totalFriendRequests }}

            multi-compare(v-if="isMobileView && isMultiCompareActive" @saveComparedPersons="saveComparedPersons")
            ._head-group
                ._search
                    ._search-icon
                        svg-icon._search-friends-icon(icon-name="search")
                    input._search-input(type="text" v-model="searchText" :placeholder="searchPlaceholder")

            ._several-ctrl(v-if="isMultiCompareActive")
                label.checkbox(for="all-persons")
                    input(type="checkbox" v-model="allPersons")._real#all-persons
                    ._fake
                        svg-icon._icon(icon-name="check")
                    ._text {{ locale("select-all") }}
        //- Perfect scrollbar have to appear on desktop
        perfect-scrollbar._content(v-if="persons && pageWidth >= 991", ref="scrollWrapper", :options="{handlers: ['keyboard', 'wheel'], swipeEasing: false, wheelSpeed: 0.2}" infinite-wrapper)
            person-short-card(
                v-for="(person, index) in persons",
                :key="person.personId",
                :person="person",
                :active-person-details-id="activeDetailsPersonId",
                :index="index + 1",
                :severalPersons="isMultiCompareActive",
                :tab="type",
                :class="{ shine: !checkedPersons }",
                @changeRequestsCount="changeRequestsCount",
                @change-active-person-details-id="setActivePersonCardId",
                :has-compare-options="!isMultiCompareActive",
                :active-person-uuid="activePersonUuid",
                @changeActivePersonUuid="changeActivePersonUuid",
                @removed="removePerson(person)",
                :all-features-filled="person.allFeaturesFilled"

            )
            infinite-loading._status-bar(:identifier="infinityId", @infinite="infiniteHandler")
                Loading(slot="spinner")
                ._status-bar-text(slot="no-more")
                ._status-bar-text(slot="no-results")
                    template(v-if="type === 'celebrity'") {{ locale("no-with-params") }}
                    template(v-if="type === 'friends'") {{ locale("no-friends") }}
                    template(v-if="type === 'requests'") {{ locale("no-requests") }}
        //- perfect-scrollbar calls a bug on mobiles, so that's why I removed it
        ._content(v-if="persons && pageWidth < 991", ref="scrollWrapper", infinite-wrapper)
            person-short-card(
                v-for="(person, index) in persons",
                :key="person.personId",
                :person="person",
                :active-person-details-id="activeDetailsPersonId",
                :index="index + 1",
                :severalPersons="isMultiCompareActive",
                :tab="type",
                :class="{ shine: !checkedPersons }",
                @changeRequestsCount="changeRequestsCount",
                @change-active-person-details-id="setActivePersonCardId",
                :has-compare-options="!isMultiCompareActive",
                :active-person-uuid="activePersonUuid",
                @changeActivePersonUuid="changeActivePersonUuid",
                @removed="removePerson(person)",
                :all-features-filled="person.allFeaturesFilled"


            )
            infinite-loading._status-bar(:identifier="infinityId", @infinite="infiniteHandler")
                Loading(slot="spinner")
                ._status-bar-text(slot="no-more")
                ._status-bar-text(slot="no-results")
                    template(v-if="type === 'celebrity'") {{ locale("no-with-params") }}
                    template(v-if="type === 'friends'") {{ locale("no-friends") }}
                    template(v-if="type === 'requests'") {{ locale("no-requests") }}
</template>

<script>
import { friendMixins } from "../../mixins/friendMixins";
import InfiniteLoading from "vue-infinite-loading";
import PersonShortCard from "./person-short-card/person-short-card";
import MultiCompare from "../person/multi-compare/multi-compare";

export default {
    name: "PersonList",
    components: {
        MultiCompare,
        PersonShortCard,
        InfiniteLoading,
    },
    mixins: [friendMixins],
    props: {
        type: {
            require: true,
            default: "celebrity",
            type: String
        },
    },
    data: () => ({
        tab: "FRIENDS", // Current active tab
        page: 1, // Current list page
        personPerPage: 15,
        persons: [], // Current persons list
        multiCompareStatus: false,
        comparedPersons: [], // persons for show compare result on mobile
        infinityId: 1,
        isLoadingList: 0,
        totalFriends: 0, // Number of friends
        totalFriendRequests: 0, // Number of requests to friends
        searchText: "", // Search string
        searchTimeout: "", // Search timeout
        checkedPersons: 0,
        filter: {
            gender: "ALL",
            age: "ALL",
            sort: "DATE",
            compare: "ALL",
            term: "",
        },
        runningTasks: "",
        runningTasksErrors: "",
        sentUploadPhotoRequests: "",
        readyTasks: "",
        totalTasks: "",
        errorTasks: "",
        activeDetailsPersonId: null,
        activePersonUuid: null,
    }),
    computed: {
        allPersons: {
            get() {
                return this.persons.every((n) => n.checked);
            },
            set(val) {
                this.persons.forEach((n) => (n.checked = val));
            },
        },
        isMultiCompareActive: (state) =>
            state.$store.getters["multiCompare/isActive"],
        searchPlaceholder: (state) => {
            if (state.type === "celebrity") {
                return state.locale("search.celebrity");
            }
            if (state.type === "friends") {
                return state.locale("search.friends");
            }
            if (state.type === "requests") {
                return state.locale("search.requests");
            }
            return state.locale("search.users");
        },
        storeFilter: (state) => state.$store.getters["personFilters"],
    },
    watch: {
        storeFilter(val) {
            this.filterResults(val);
        },
        isMultiCompareActive() {
            this.persons.every((n) => (n.checked = false));
        },
        searchText(str) {
            let term = str.trim();
            if (term.length !== 0) {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.term = term.trim();
                    this.changeType(this.tab);
                }, 1000);
            } else {
                this.searchTimeout = setTimeout(() => {
                    this.filter.term = "";
                    this.changeType(this.tab);
                }, 1000);
            }
        },
        persons: {
            handler() {
                let count = 0,
                    persons = [];
                this.persons.forEach((person) => {
                    if (person.checked) {
                        persons.push(person);
                        count++;
                    }
                });
                this.checkedPersons = count;
                this.$store.dispatch("multiCompare/setPersons", persons);
            },
            deep: true,
        },
        infinityId: function (newValue, prevValue) {
            if (newValue !== prevValue) {
                this.isLoadingList = 0;
            }
        },
    },
    created() {
        this.drawCounters();
    },
    destroyed() {
        this.$store.dispatch("multiCompare/setPersons", []);
        this.$store.dispatch("multiCompare/setSuccessStatus", false);
        this.$store.dispatch("multiCompare/setStatus", false);
    },
    methods: {
        saveComparedPersons(persons) {
            this.comparedPersons = persons;
        },
        changeType(tab) {
            this.tab = tab;
            this.page = 1;
            this.persons = [];
            this.infinityId += 1;
        },
        filterResults(filter) {
            this.filter = filter;
            this.changeType(this.tab);
        },
        changeMultiCompareStatus() {
            this.multiCompareStatus = !this.multiCompareStatus;
            this.$store.dispatch(
                "multiCompare/setStatus",
                this.multiCompareStatus,
            );
        },
        async drawCounters() {
            await this.friendsCount()
                .then((response) => {
                    this.totalFriends = response.data.friendsCount;
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError("friendsCount"),
                    );
                });
            await this.friendRequestsCount()
                .then((response) => {
                    this.totalFriendRequests =
                        response.data.friendRequestsCount;
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError(
                            "friendRequestsCount",
                        ),
                    );
                });
        },
        async infiniteHandler($state) {
            let listType =
                this.type === "friends"
                    ? "friendList"
                    : this.type === "compare-with-me"
                        ? "analyzedWithMePersonsList"
                        : this.type === "celebrity"
                            ? "celebritiesList"
                            : "friendRequests";
            let requestType = this.type;
            if (this.type === "requests") {
                requestType = "REQUESTS";
            }

            this.isLoadingList = 1;
            await this.getPersons(
                requestType,
                this.page,
                this.personPerPage,
                this.filter,
            )
                .then((r) => {
                    let response = r.data[listType];
                    if (
                        this.type === "celebrity" ||
                        this.type === "compare-with-me"
                    ) {
                        response = response.list;
                    }
                    if (response != null && response.length) {
                        this.page += 1;
                        response.forEach((person) => {
                            person.checked = false;
                            person.type = this.type;
                            person.compareStatus = false;
                            person.showType = true;

                            this.comparedPersons.forEach((item) => {
                                if (person.userId === item.userId) {
                                    person.compareStatus = true;
                                }
                            });
                            if (this.filter.sort === "COMPATIBILITY") {
                                person.compareStatus = true;
                            }

                            if (
                                !this.persons.find(
                                    (p) => p.personId === person.personId,
                                )
                            ) {
                                this.persons.push(person);
                            }
                        });

                        $state.loaded();
                        this.isLoadingList = 0;
                    } else {
                        $state.complete();
                        this.isLoadingList = 1;
                    }
                })
                .catch(() => {
                    this.isLoadingList = 1;
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError(
                            "personListNotReceived",
                        ),
                    );
                });
        },
        resetFilter: () => ({
            gender: "ALL",
            age: "ALL",
            sort: "DATE",
            compare: "ALL",
            term: "",
        }),
        changeRequestsCount(type, index) {
            if (type === "ADDED") {
                this.totalFriends++;
                this.totalFriendRequests--;
            } else {
                this.totalFriendRequests--;
            }
            this.persons.splice(index - 1, 1);
        },
        setActivePersonCardId(id) {
            this.activeDetailsPersonId = id;
        },
        changeActivePersonUuid(id) {
            this.activePersonUuid = id;
        },
        removePerson(person) {
            this.persons.splice(this.persons.indexOf(person), 1);
        },
    },
};
</script>

<style lang="scss">
@import "person-list";
</style>
