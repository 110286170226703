<template lang="pug">
    .content__main
        person-list(:type="personsType" :key="personsType")
</template>

<script>
import PersonList from "../components/person-list/person-list";
import { friendMixins } from "../mixins/friendMixins";

export default {
    components: { PersonList },
    mixins: [friendMixins],
    data: () => ({
        type: "all",
        types: {
            all: "люди",
            bloggers: "блогеры",
            artists: "актёры",
            politicians: "политики",
            business: "бизнес",
            models: "модели",
            vocalists: "певцы",
            warriors: "бойцы",
            science: "учёные",
            media: "медиа",
            medics: "медики",
        },
        friendsTotal: 0,
    }),
    computed: {
        personsType: (state) =>
            state.$route.params.personType
                ? state.$route.params.personType
                : "celebrity",
    },
    watch: {
        async personsType(newType) {
            await this.$store.dispatch("setPage", newType);
            if (newType === "friends") this.getFriendsCount();
        },
    },
    async created() {
        await this.$store.dispatch("setPage", this.personsType);
        if (this.personsType === "friends") this.getFriendsCount();
    },
    mounted() {
        this.type = this.$route.params.type ? this.$route.params.type : "all";
    },
    methods: {
        async getFriendsCount() {
            await this.friendsCount()
                .then((r) => {
                    this.friendsTotal = r.data.friendsCount;
                })
                .catch((error) => {
                    // TODO: handle error
                    alert("Can't get friends count: " + error);
                });
        },
    },
    metaInfo() {
        if (this.personsType === "friends" || this.personsType === "requests") {
            return {
                title:
                    this.personsType === "friends"
                        ? `Мои друзья - ${this.friendsTotal} ` +
                          (this.friendsTotal > 4 ? "друзей" : "друга") +
                          " - Merlinface.com"
                        : "Заявки в друзья - Merlinface.com",
                meta: [
                    {
                        name: "description",
                        content:
                            "Здесь отображаются мои друзья в социальной сети Merlinface.com",
                        vmid: "description",
                    },
                    {
                        property: "og:description",
                        content:
                            "Здесь отображаются мои друзья в социальной сети Merlinface.com",
                        vmid: "og_description",
                    },
                    {
                        name: "robots",
                        content: "noindex,nofollow",
                    },
                    {
                        name: "googlebot",
                        content: "noindex",
                    },
                ],
            };
        }
        return {
            title: `Известные ${
                this.types[this.type]
            } - анализ характера искусственным интелектом Merlin AI`,
            meta: [
                {
                    name: "description",
                    content:
                        "Здесь отображаются мои друзья в социальной сети Merlinface.com",
                    vmid: "description",
                },
                {
                    property: "og:description",
                    content:
                        "Здесь отображаются мои друзья в социальной сети Merlinface.com",
                    vmid: "og_description",
                },
            ],
        };
    },
};
</script>
